.label:not(g) {
  background-color: $grey-dk-100;
  margin: 3pt 1rem 3pt 2pt;
}

.label-blue:not(g) {
  background-color: $blue-100;
}

.label-green:not(g) {
  background-color: $green-200;
}

.label-purple:not(g) {
  background-color: $purple-100;
}

.label-red:not(g) {
  background-color: $red-200;
}

.label-yellow:not(g) {
  color: $grey-dk-200;
  background-color: $yellow-200;
}

@media screen and (max-width: $content-width) {
  .main-content {
    margin-bottom: 4rem;
  }
  .site-footer hr {
    margin-bottom: 1rem;
  }
  .site-footer p {
    margin: 5px 0;
  }
  .site-nav hr {
    display: none;
  }
}

@media screen and (min-width: $content-width) {
  .site-footer hr {
    display: none;
  }
}

.main-content dt::after {
  content: "";
}

.main-content {
  ul, ol {
    ul, ol {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
}
